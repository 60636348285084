@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  @apply text-white;
  box-sizing: border-box;
  position: relative;
  font-family: 'Baumans';
  outline: none !important;
}

*:focus {
  outline: none !important;
}

body {
  outline: 0;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, #root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

h1, h2, h3, h4, h5, h6,
h1 *, h2 *, h3 *, h4 *, h5 *, h6 * {
  font-family: 'MuseoModerno';
  letter-spacing: 3px;
}

label, button {
  font-family: 'MuseoModerno';
  font-weight: 900;
}

@font-face {
  font-family: 'Baumans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/Baumans-Regular.ttf');
}

@font-face {
  font-family: 'Monoton';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/Monoton-Regular.ttf');
}

@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/MuseoModerno-Thin.ttf');
}

/* @font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/MuseoModerno-ExtraLight.ttf');
} */

@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/MuseoModerno-Light.ttf');
}

/* @font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/MuseoModerno-Regular.ttf');
}

@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/MuseoModerno-Medium.ttf');
}

@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/MuseoModerno-SemiBold.ttf');
} */

/* @font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/MuseoModerno-Bold.ttf');
}

@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/MuseoModerno-ExtraBold.ttf');
} */

@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/MuseoModerno-Black.ttf');
}
